<template>
    <vue-html2pdf
        v-if="sellIsLoaded"
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="A4"
        pdf-orientation="landscape"
        pdf-content-width="750px"
        ref="pdf"
        :html-to-pdf-options="htmlToPdfOptions"
    >
        <section slot="pdf-content">
            <WorksheetPdf :sell="sell" :sell-is-loaded="sellIsLoaded"></WorksheetPdf>
        </section>
    </vue-html2pdf>
</template>

<script>
  import api from '@/api/api';
  import VueHtml2pdf from 'vue-html2pdf';
  import WorksheetPdf from '@/components/pdf/worksheet-pdf.vue';
  import SellService from "@/services/sell.service";

  export default {
    data() {
      return {
        SellService,
        sell: {},
        sellIsLoaded: false,
        htmlToPdfOptions: {
            margin: [2, 5, 0, 5],
            image: {type: 'jpeg', quality: 1},
            html2canvas: {
              dpi: 192,
              scale: 4,
              letterRendering: true,
              useCORS: true
            }
        }
      }
    },
    mounted() {
      if (this.$route.params.id) {
        this.loadSell(this.$route.params.id);
      }
    },
    components: {
        VueHtml2pdf,
        WorksheetPdf
    },
    computed: {
      sellName() {
        if (!this.sell.id) {
          return 'Vente.pdf';
        } 
        return SellService.getNumber(this.sell.sellId, this.sell.created_date, this.sell.company) + '.pdf';
      }
    },
    methods: {
      loadSell(id) {
        var that = this;
        api
          .get('/sales/' + id)
          .then(
              (response) => {
                  if (response && response.status == 200) {
                      this.sell = response.data;
                      this.sellIsLoaded = true;
                      
                      setTimeout(() => {
                          that.$refs.pdf.generatePdf();
                      }, 2000);
                  }
              }
          )
      },
    }
  };
</script>

<style lang="scss">
  .show-layout {
    background-color: white !important;
  }
</style>