<template>
    <div class="row mt-2">
        <div class="col-12">
            <div class="title">Isolation</div>
            <table class="table table-nowrap table-centered mb-0 table-slim">
                <tbody>
                    <tr>
                        <td><b>Combles aménagés</b></td>
                        <td>{{ content.isolation.accessibleAttic }}</td>
                        <td><b>Combles perdus</b></td>
                        <td>{{ content.isolation.lostAttic }}</td>
                    </tr>
                    <tr>
                        <td><b>Hauteur de flèche comble</b></td>
                        <td>{{ content.isolation.arrowHeight }}</td>
                        <td><b>Écartement chevrons</b></td>
                        <td>{{ content.isolation.rafter }}</td>
                    </tr>
                    <tr>
                        <td><b>Isolant à évacuer</b></td>
                        <td>{{ content.isolation.insulatorToRemove }}</td>
                        <td><b>Isolant à poser</b></td>
                        <td>{{ content.isolation.insulatorToAdd }}</td>
                    </tr>
                    <tr>
                        <td><b>Présence d'une ventilation</b></td>
                        <td>{{ content.isolation.ventilation }}</td>
                        <td><b>Boite de dérivation</b></td>
                        <td>{{ content.isolation.shuntBox }}</td>
                    </tr>
                    <tr>
                        <td><b>Conduit de cheminée</b></td>
                        <td>{{ content.isolation.shaft }}</td>
                        <td><b>Présence HPV sous tuile</b></td>
                        <td>{{ content.isolation.hpv }}</td>
                    </tr>
                    <tr>
                        <td><b>Trappe d'accès</b></td>
                        <td>{{ content.isolation.trap }}</td>
                        <td><b>Plancher existant</b></td>
                        <td>{{ content.isolation.floor }}</td>
                    </tr>
                    <tr>
                        <td><b>Trou d'homme</b></td>
                        <td>{{ content.isolation.hole }}</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WorksheetToiture',
        props: ['content']
    }
</script>

<style lang="scss" scoped>
    .color-FP {
        $color: #354E85;

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .color-MP {
        $color: #0078B3;

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .color-3M {
        $color: #70B632;

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .color-PH {
        $color: #AE0C21;

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }
    
    .title, td {
        color: black;
        line-height: 1.5;
    }

    .title {
        font-weight: bold;
    }
</style>