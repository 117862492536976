<template>
    <div class="row mt-2">
        <div class="col-12">
            <div class="title">Toiture</div>
            <table class="table table-nowrap table-centered mb-0 table-slim">
                <tbody>
                    <tr>
                        <td><b>Type de tuile</b></td>
                        <td>{{ content.toiture.tileType }}</td>
                        <td><b>Couleur de tuile</b></td>
                        <td>{{ content.toiture.tileColor }}</td>
                    </tr>
                    <tr>
                        <td><b>Type de charpente</b></td>
                        <td>{{ content.toiture.structure }}</td>
                        <td><b>Type de gouttière</b></td>
                        <td>{{ content.toiture.gutter }}</td>
                    </tr>
                    <tr>
                        <td><b>Hauteur sous gouttière façade avant</b></td>
                        <td>{{ content.toiture.heightFront }}</td>
                        <td><b>Hauteur sous gouttière façade arrière</b></td>
                        <td>{{ content.toiture.heightBack }}</td>
                    </tr>
                    <tr>
                        <td><b>Hauteur de faîtage</b></td>
                        <td>{{ content.toiture.ridgeTiling }}</td>
                        <td><b>Échaufaudage</b></td>
                        <td>{{ content.toiture.scaffolding }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WorksheetToiture',
        props: ['content']
    }
</script>

<style lang="scss" scoped>
    .color-FP {
        $color: #354E85;

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .color-MP {
        $color: #0078B3;

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .color-3M {
        $color: #70B632;

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .color-PH {
        $color: #AE0C21;

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }
    
    .title, td {
        color: black;
        line-height: 1.5;
    }

    .title {
        font-weight: bold;
    }
</style>