<template>
    <div class="small-fs" :class="`color-${sell.company.prefix}`">
        <div class="row">
            <div class="col-12 text-center">
                <img class="logo" v-if="sell.company" :src="'/logos/' + sell.company.prefix + '.png'">
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 text-center">
                <h1>FICHE CHANTIER</h1>
                <div class="section--bordered p-1">
                    <div class="info">
                        {{ sell.customer.firstname }} {{ sell.customer.lastname }}<br>
                        {{ sell.customer.address }}, {{ sell.customer.postcode }} {{ sell.customer.city }}<br>
                            {{ (sell.customer.phone != '') ? sell.customer.phone : '' }}{{ (sell.customer.mobile != '') ? (sell.customer.phone != '' ? ' - ' : '') + sell.customer.mobile : '' }}<br>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <div>
                    <table class="table table-nowrap table-centered mb-0 table-slim">
                        <tbody>
                            <tr class="larger">
                                <td><b>Énoncé de la tâche</b></td>
                                <td colspan="3">{{ sell.workSheet.task }}</td>
                            </tr>
                            <tr>
                                <td><b>Surface</b></td>
                                <td>{{ sell.workSheet.surface }}</td>
                                <td><b>Catégorie</b></td>
                                <td>{{ sell.workSheet.serviceCategory.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <WorksheetToiture v-if="sell.workSheet.serviceCategory.id === 3" :content="sell.workSheet.content"></WorksheetToiture>
                <WorksheetIsolation v-if="sell.workSheet.serviceCategory.id === 4" :content="sell.workSheet.content"></WorksheetIsolation>
                <WorksheetFacade v-if="sell.workSheet.serviceCategory.id === 5" :content="sell.workSheet.content"></WorksheetFacade>
                <WorksheetToiture v-if="sell.workSheet.serviceCategory.id !== 3" :content="sell.workSheet.content"></WorksheetToiture>
                <WorksheetIsolation v-if="sell.workSheet.serviceCategory.id !== 4" :content="sell.workSheet.content"></WorksheetIsolation>
                <WorksheetFacade v-if="sell.workSheet.serviceCategory.id !== 5" :content="sell.workSheet.content"></WorksheetFacade>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <div class="section--bordered p-1">
                    <div><b class="title">Accès camion</b> : {{ sell.workSheet.accessibility }}</div>
                    <div v-if="sell.workSheet.comment && sell.workSheet.comment != ''">
                        <div class="title">Précisions complémentaires</div>
                        <div class="comment mt-1">{{ sell.workSheet.comment }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="info info--small mt-3 text-center">
                    {{ sell.company.legal }}<br>
                    {{ sell.company.name }} - {{ sell.company.address }} {{ sell.company.postcode }} <span class="uppercase">{{ sell.company.city }}</span> - {{ sell.company.telephone }} - {{ sell.company.email }}
                </div>
                <div class="small-square"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import WorksheetToiture from "@/components/pdf/worksheet/worksheet-toiture";
    import WorksheetIsolation from "@/components/pdf/worksheet/worksheet-isolation";
    import WorksheetFacade from "@/components/pdf/worksheet/worksheet-facade";

    export default {
        name: 'WorksheetPdf',
        components: {
            WorksheetToiture,
            WorksheetIsolation,
            WorksheetFacade
        },
        data() {
            return {}
        },
        props: ['sell', 'sellIsLoaded'],
    }
</script>

<style scoped lang="scss">
    .logo {
        max-width: 300px;
    }
    .larger {
        font-size: 0.9rem;
    }
    .small-fs {
        font-size: 0.7rem;
    }

    .color-FP {
        $color: #354E85;

        tbody {
            border-top: 1px solid $color !important;
        }

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .color-MP {
        .logo {
            max-width: 200px;
        }
        $color: #0078B3;

        tbody {
            border-top: 1px solid $color !important;
        }

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .color-3M {
        $color: #70B632;

        tbody {
            border-top: 1px solid $color !important;
        }

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .color-PH {
        $color: #AE0C21;

        tbody {
            border-top: 1px solid $color !important;
        }

        .section--bordered, table, .small-square {
            border: 1px solid $color;
        }
    }

    .small-square {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 10px;
        bottom: 2px;
    }

    .info, .title, label, td {
        color: black;
        line-height: 1.5;
    }

    .info {
        &--small {
            font-size: 10px;
        }

        &--large {
            font-size: 18px;
        }
    }

    .title {
        font-weight: bold;
    }

    thead {
        color: black;
    }

    .comment {
        white-space: pre-wrap;
    }

    .table--small {
        font-size: 8px;
        th, td {
            padding: 2px;
        }
    }

    .pb-6 {
        padding-bottom: 6em !important;
    }

    .uppercase {
        text-transform: uppercase;
    }

    .row {
        break-inside: avoid;
    }

    td.description {
        white-space: pre-wrap;
    }
</style>